import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: 'Yohane.cc - 学妹的不知名小站'
    }
  },
  {
    path: '/livebg',
    name: 'LiveBg',
    component: () => import('../views/LiveBg.vue'),
    meta: {
      title: '直播背景板'
    }
  },{
    path: '/colomai',
    name: 'Mai',
    component: () => import('../views/Mai.vue'),
    meta: {
      title: '24K纯舞萌抽歌器'
    }
  },{
    path: '*',
    name: 'NotFoundPage',
    component: () => import('../views/404.vue'),
    meta: {
      title: '404 Not Found'
    }
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  console.log(to);
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
