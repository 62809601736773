<template>
    <div id="app">
      <keep-alive>
      <router-view />
      </keep-alive>
    </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'app',
  data() {
    return {
      zh_CN,
    }
  }
}
</script>

<style>

</style>
